<!-- 确认兑换页面 -->
<template>
  <div class="top_order_info flex_row_start_center">
    <div class="top_logo">
      <div :style="`background-image:url(${configInfo.main_site_logo})`"></div>
    </div>
  </div>
  <div class="confirm_order_container">
    <!-- v-if="virtual.isVG==1" -->
    <div class="receive_info">
      <div class="receive_info_title">
        <span>{{ L['收货信息'] }}</span>
      </div>
      <div v-if="orderAddress.data.memberName" class="receive_info_content flex_row_start_center">
        <div class="content_left flex_column_between_start">
          <span class="member">收货人：{{ orderAddress.data.memberName }}</span>
          <span>{{ L['联系方式'] }}：{{ orderAddress.data.telMobile }}</span>
          <span
            :title="orderAddress.data.addressAll + orderAddress.data.detailAddress"
            class="address_detail overflow_ellipsis"
          >
            {{ L['收货地址'] }}：{{ orderAddress.data.addressAll }}
            {{ orderAddress.data.detailAddress }}
          </span>
        </div>
        <div class="content_right flex_column_center_center">
          <div class="replace_address pointer" @click="changeAddress">{{ L['更换地址'] }}</div>
          <div class="add_address pointer" @click="addAddress">{{ L['新建地址'] }}</div>
        </div>
      </div>
      <div v-else class="receive_info_content flex_row_center_center">
        <div class="add_address nomartop pointer" @click="addAddress">{{ L['新建地址'] }}</div>
      </div>
    </div>
    <div
      class="goods_info_content"
      v-for="(item,index) in allData.data.storeGroupList"
      :key="index"
    >
      <div class="store_title">{{item.storeName}}</div>
      <div class="goods_list">
        <div class="goods_item" v-for="(ite,ind) in item.productList" :key="ind">
          <div class="goods_item_left">
            <img :src="ite.image" alt />
            <div class="goods_name">{{ite.goodsName}}</div>
          </div>
          <div class="goods_item_right">
            <div class="goods_price">￥{{ite.price}}</div>
            <div class="goods_num">*{{ite.buyNum}}</div>
          </div>
        </div>
      </div>
      <div class="leave_message">
        <div class="leave_title">给卖家留言：</div>
        <el-input class="input_leave" placeholder="请输入" type="textarea" v-model="message" />
      </div>
      <div class="product_amount">
        <div class="item">
          <span class="item_title">商品金额：</span>
          <span class="item_price">￥{{item.goodsAmount}}</span>
        </div>
        <div class="item">
          <span class="item_title">运费：</span>
          <span class="item_price">￥{{item.expressFee}}</span>
        </div>
      </div>
    </div>
    <div class="btn_content">
      <div class="confrim_btn flex_row_center_center" @click="goBuy">确认兑换</div>
    </div>
  </div>

  <!-- 地址选择弹窗 start -->
  <el-dialog
    title="更换地址"
    v-model="show_select_address"
    width="500px"
    customClass="select_address_width"
    :before-close="handleClose"
    lock-sroll="false"
  >
    <div class="out_stock_dialog address_con">
      <div
        v-for="(item, index) in address_list.data"
        :key="index"
        @click="selectAddress(index)"
        :class="{ address_item: true, flex_column_start_start: true, select: current_address_index == index }"
      >
        <span>{{ L['收货人'] }}：{{ item.memberName }}</span>
        <span>{{ L['联系方式'] }}：{{ item.telMobile }}</span>
        <div class="address_text" :title="item.addressAll + item.detailAddress">
          <span>{{ L['收货地址'] }}：</span>
          <span class="overflow_ellipsis_clamp2">{{ item.addressAll }} {{ item.detailAddress }}</span>
        </div>
        <img v-if="current_address_index == index" class="selected" :src="invoice_selected" alt />
      </div>
    </div>
    <div class="btn_con flex_row_center_center">
      <div class="remove red" @click="confirmChangeAddress()">{{ L['提交修改'] }}</div>
    </div>
  </el-dialog>
  <!-- 地址选择弹窗 end -->
  <SldAddressOperation
    v-if="addressVisibleFlag"
    @close="close"
    @refreshAddress="refreshAddress"
    :visibleFlag="true"
    :type="type"
  />
</template>
<script>
import { getCurrentInstance, onMounted, onUnmounted,ref, reactive } from "vue";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import { ElMessage } from "element-plus";
import SldAddressOperation from "@/components/AddressOperation";
export default {
  name: "",
  components: {
    SldAddressOperation
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    const { proxy } = getCurrentInstance();
    const L = proxy.$getCurLanguage();
    const configInfo = ref(store.state.configInfo);
    const type = ref("add");
    const isPreventClick = ref(false)
    const timer = ref(0); //定时器
    const orderAddress = reactive({ data: "" });
    const allData = reactive({ data: {} });
    const address_list = reactive({ data: [] });
    const current_address_index = ref(0);
    const show_select_address = ref(false);
    const addressVisibleFlag = ref(false);

    //获取地址列表
    const getAddressList = () => {
      proxy
        .$get("v3/member/front/memberAddress/list")
        .then(res => {
          if (res.state == 200) {
            address_list.data = res.data.list;
            if (res.data.list.length > 0 && !orderAddress.data.addressId) {
              var default_address = res.data.list.filter(function(item) {
                return item.isDefault == 1;
              });
              if (default_address.length > 0) {
                orderAddress.data = default_address[0];
              } else {
                orderAddress.data = res.data.list[0];
              }
              if (localStorage.getItem("addressId")) {
                let addressID = localStorage.getItem("addressId");
                if (res.data.list.filter(i => i.addressId == addressID)[0]) {
                  orderAddress.data = res.data.list.filter(
                    i => i.addressId == addressID
                  )[0];
                }
              }
            }

            confirmOrder(1);
          } else {
            confirmOrder(2);
            ElMessage(res.msg);
          }
        })
        .catch(() => {
          //异常处理
        });
    };
    //切换地址
    const selectAddress = index => {
      current_address_index.value = index;
    };
    //确认修改地址
    const confirmChangeAddress = () => {
      orderAddress.data = address_list.data[current_address_index.value];
      show_select_address.value = false;
      confirmOrder(2);
      localStorage.setItem("addressId", orderAddress.data.addressId);
    };
    //弹出地址选择框
    const changeAddress = () => {
      getAddressList();
      show_select_address.value = true;
    };

    //关闭弹窗
    const close = () => {
      addressVisibleFlag.value = false;
    };
    //弹出新建地址窗口
    const addAddress = () => {
      addressVisibleFlag.value = true;
    };
    const refreshAddress = (addressInfo, addressId) => {
      orderAddress.data = addressInfo;
      orderAddress.data.addressId = addressId;
      localStorage.setItem("addressId", orderAddress.data.addressId);
    };
    //用于切换地址，使用优惠券，获取信息，运费等
    const confirmOrder = type => {
      let param = {};
      param.source = 3;
      param.isPick = true;
      param.pickId = route.query.pickId;
      param.cartId = route.query.cartId;
      param.isCart = false;  
      proxy
        .$post(
          "v3/business/front/orderOperate/confirm",
          param,
          "application/json"
        )
        .then(res => {
          if (res.state == 200) {
            allData.data = res.data;
          } else {
            ElMessage(res.msg);
          }
        });
    };
    //跳转订单页
    const goBuy = () => {
      let param = {};
      param.source = 3;
      param.orderFrom = 1;
      param.giftId = route.query.pickId;
      param.isPick = true;
      param.pickId = route.query.pickId;
      param.addressId = orderAddress.data.addressId;
      let storeInfoList = [];
      allData.data.storeGroupList.map(item => {
        let storeitem = {};
        storeitem.storeId = item.storeId;
        storeInfoList.push(storeitem);
      });
      param.storeInfoList = storeInfoList;

      param.isCart = false;
      param.cartId = route.query.cartId;

      proxy
        .$post(
          "/v3/business/front/orderOperate/submit",
          param,
          "application/json"
        )
        .then(res => {
          if (res.state == 200) {
            let paySn = res.data.paySn;
            proxy.$sldStatEvent({ behaviorType: "buy" });
            timer.value = setInterval(() => {
              queryPayState(paySn);
            }, 800);
          } else {
            ElMessage(res.msg);
            isPreventClick.value = false;
          }
        });
    };
    // 定时查询是否可以支付
    const queryPayState = paySn => {
      getPayInfo(paySn);
    };
    //获取订单支付数据
    const getPayInfo = paySn => {
      proxy
        .$get("v3/business/front/orderPay/payInfo", {
          paySn: paySn,
          payFrom: 1
        })
        .then(res => {
          if (res.state == 200) {
            isPreventClick.value = false;
            if (res.data.dealState == 3) {
              setTimeout(() => {
              router.replace({
                path: "/member/order/list"
              });
            }, 2000);
              //清除定时器
              if (timer.value) {
                clearInterval(timer.value);
                timer.value = null;
              }
            } else if (res.data.dealState == 2) {
                //清除定时器
              if (timer.value) {
                clearInterval(timer.value);
                timer.value = null;
              }
            //   ElMessage(L["提交订单失败，请稍后重试"]);
             ElMessage.success('提交订单成功');
            setTimeout(() => {
              router.replace({
                path: "/member/order/list"
              });
            }, 2000);
              isPreventClick.value = false;
              
            }
          } else if (res.state == 267) {
            ElMessage.success(res.msg + L[",2s后自动跳转订单列表"]);
            //清除定时器
            if (timer.value) {
              clearInterval(timer.value);
              timer.value = 0;
            }
            setTimeout(() => {
              router.replace({
                path: "/member/order/list"
              });
            }, 2000);
          } else {
            //清除定时器
            isPreventClick.value = false;
            if (timer.value) {
              clearInterval(timer.value);
              timer.value = 0;
            }
            ElMessage(res.msg);
          }
        });
    };
    onMounted(() => {
      getAddressList();
    });
     onUnmounted(() => {
      if (timer.value) {
        clearInterval(timer.value);
        timer.value = 0;
      }
    });
    return {
      L,
      configInfo,
      timer,
      show_select_address,
      orderAddress,
      address_list,
      current_address_index,
      type,
      addressVisibleFlag,
      selectAddress,
      confirmChangeAddress,
      changeAddress,
      addAddress,
      refreshAddress,
      close,
      allData,
      goBuy,
      queryPayState,
      getPayInfo,
      isPreventClick,
    };
  }
};
</script>
<style lang='scss'>
@import "@/style/confirmRedemption.scss";
.select_address_width {
  width: 500px !important;
}

.el-dialog__body {
  padding-top: 10px;
}
</style>